<template>
  <LandingPageLayout>
    <div class="page-loader" v-if="isPartnersLoading">
      <LxpLoader />
    </div>
    <Banner
      :bannerTitle="bannerTitle"
      :bannerContent="bannerContent"
      :bannerDesktopImage="bannerDesktopImage"
      :bannerBtn="false"
      :extendCaptionWidth="true"
    />
    <DownloadApp />
    <Partners
      :numberOfCards="20"
      :showExploreBtn="false"
      @loading-state="handleLoadingState"
      class="discovery-page"
    />
    <StayTuned />
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "../components/Layout/LandingPageLayout.vue";
import Banner from "@/components/Landing/Banner.vue";
import DownloadApp from "@/components/Landing/DownloadApp.vue";
import Partners from "@/components/Landing/Partners.vue";
import StayTuned from "@/components/Landing/StayTuned.vue";
import { LxpLoader } from "didactica";
export default {
  components: {
    LandingPageLayout,
    Banner,
    DownloadApp,
    Partners,
    StayTuned,
    LxpLoader
  },
  data() {
    return {
      bannerDesktopImage: require("@/assets/images/landing/banner/discovery-banner.png"),
      isPartnersLoading: false
    };
  },
  computed: {
    bannerTitle() {
      return this.$t("course_discovery.banner_section.title");
    },
    bannerContent() {
      return this.$t("course_discovery.banner_section.content");
    }
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  methods: {
    handleLoadingState(value) {
      this.isPartnersLoading = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $brand-neutral-0;
  z-index: 99;
  @include flex-horizontal-center;
}
</style>
